import * as R from 'ramda';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router-dom';

import book from 'routes/book';
import { CustomNavLink } from 'routes/providers/customNavLink.jsx';

import NavPoint from 'assets/sec-nav-point.svg';

import styles from 'components/nav/Menu/styles.scss';

const PAGES = [
  {
    text: 'TOURS_MENU',
    path: book.tourscanner.path,
    additionActivePaths: [book.tourscanner.children.offer]
  },
  {
    text: 'CHARTERS_MENU',
    path: book.charters.path,
  },
  {
    text: 'BUS_TICKETS_MENU',
    allowedCRMS: ['tat'],
    path: book.busTickets.path,
  },
  {
    text: 'TICKETS_MENU',
    path: book.airtickets.path,
    allowedCRMS: ['tat'],
  },
  {
    text: 'BASKETS_MENU',
    path: book.tourscanner.children.cart,
  },
  {
    text: 'COLLECTIONS_MENU',
    path: book.tourscanner.children.collections,
  },
  {
    text: 'RATES_PAGE',
    path: book.rates.path,
  },
  {
    text: 'BOOKING_CONDITIONS',
    path: `${book.news.path}?news_selection=2`,
    allowedCRMS: ['tat', 'nakanikuly'],
  },
];

const NavigationMenu = () => {
  const { t } = useTranslation('TS_MENU');

  return (
    <div className={styles.menuContainer}>
      {R.map(({ text, path, exact = false, allowedCRMS, additionActivePaths = [] }) => (
        <CustomNavLink
          activeClassName={styles.activeLink}
          allowedCRMS={allowedCRMS}
          className={styles.menuLink}
          exact={exact}
          key={path}
          to={path}
          isActive={(match, location) => {
            if (!match) {
              return false;
            }
            if (R.isEmpty(additionActivePaths)) {
              return match.url === location.pathname;
            }
            const isAdditionActivePage = additionActivePaths.some(
              currentPath => matchPath(location.pathname, { path: currentPath, exact: true })
            );

            return match.url === location.pathname || isAdditionActivePage;
          }}
        >
          {t(text)}
          <div
            className={styles.navPoint}
            dangerouslySetInnerHTML={{ __html: NavPoint }}
          />
        </CustomNavLink>
      ),
      PAGES)}
    </div>
  );
};

export default memo(NavigationMenu);
